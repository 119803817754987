<!--
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2023-04-17 18:15:27
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2024-04-26 21:15:09
 * @Description: 
-->
<template>
	<div class="content-wrap" ref="container">
		<div class="side-menu">
			<a-menu :selectedKeys="activeMenu" mode="inline">
				<a-menu-item :key="item.name" v-for="item in menuList">
					<router-link :to="{name: item.name}">{{ item.title }}</router-link>
				</a-menu-item>
			</a-menu>
		</div>
		<div class="main" id="main">
			<breadcrumb />
			<router-view />
		</div>
	</div>
</template>
<script>
import Breadcrumb from '@/layouts/Breadcrumb'
export default {
	name: 'Personalization',
	components: {Breadcrumb},
	data() {
		return {
			menuList: [
				{title: '功能开关', name: 'OptionsSwitch'},
				{title: '个性化设置', name: 'PersonalSetting'},
				{title: '餐段设置', name: 'MealSetting'}
			]
		}
	},
	created() {},
	methods: {},
	computed: {
		activeMenu() {
			return [this.$route.name]
		}
	}
}
</script>
<style scoped lang="less">
.content-wrap {
	background-color: #f0f2f5;
	width: 100%;
	min-height: calc(100vh - 66px);
}

.side-menu {
	position: fixed;
	left: 0;
	top: 66px;
	box-sizing: border-box;
	padding-top: 25px;
	width: 208px;
	height: calc(100vh - 66px);
	background-color: #fff;
}

.main {
	min-height: calc(100vh - 66px);
	position: relative;
	margin-left: 208px;
	overflow: hidden;
	padding: 0 22px;
}
</style>
